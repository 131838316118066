<template>
   <HeaderDash />
   <SideMenu /> 
   <ContentApp /> 
</template>
<script>
// Importando los componententes pertenecientes a esta vista
import HeaderDash from '@/components/worker/dashboard/HeaderDash.vue'
import SideMenu from '@/components/worker/dashboard/SideMenu.vue'
import ContentApp from '@/components/worker/dashboard/modules/activity/app/ContentApp.vue'

export default 
{
  name: 'App',
  components: 
  {
    HeaderDash,
    SideMenu,
    ContentApp
  }
}
</script>
<style>

</style>