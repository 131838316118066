<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue">App</h3>
          <div class="d-flex justify-content-between">
            <div class="col-md-4 mb-4" align="left">
              <label for="project" class="form-label">Date</label>
              <v-date-picker v-model="GetAppPost.date" mode="Date" :model-config="modelConfig" @dayclick="GetApps()">
                <template v-slot="{ inputValue, inputEvents }">
                  <input class="form-control" :value="inputValue" v-on="inputEvents" />
                </template>
              </v-date-picker>
            </div>
          </div>
          <div class="text-end">
            <div v-if="ListApps.status != 'false'" class="cursor-pointer d-inline m-3" @click="mostrar = !mostrar "> Graphic <i class="fas fa-chart-bar"></i>
            </div>
          </div>
          <div id="graph" class="row m-2 mb-5" v-show="mostrar">
            <div class="row m-2 col-md-12 card card-cover rounded bg-white shadow-sm justify-content-center align-items-center">
              <div class="col-12 w-graph m-3 ">
                <vue3-chart-js 
                  :id="doughnutChart.id" ref="chartRef" 
                  :type="doughnutChart.type" :data="doughnutChart.data" 
                  :options="doughnutChart.options" 
                  @before-render="beforeRenderLogic">
                </vue3-chart-js>
              </div>
            </div>
          </div>
            <div  class="row m-2 mb-5" >
              <div v-if="ListApps.status != 'false'" class="row m-2 col-md-12  card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
                <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
                  <div style="display:inline !important"></div>
                  <!-- Tabla-->
                  <div class="table-responsive">
                    <table id="app-table" class="table table-striped responsive">
                      <thead>
                        <tr class="text-start">
                          <th scope="col">
                            <span class="text-blue">App Name</span>
                          </th>
                          <th scope="col">
                            <span class="text-blue">Time spent</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="mb-special">
                        <tr class="text-start" v-if="ListApps.status != 'false'" v-for="(obj, key, index) in ListApps.list" :key="index">
                          <td class="text-start">{{obj.name_process}}</td>
                          <td class="text-start">{{obj.seconds}}</td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- Paginador -->
                  </div>
                </div>
              </div>
           </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import {ref} from 'vue'
  import Api from '@/services/Request';
  import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
  import "datatables.net-dt/js/dataTables.dataTables"
  import "datatables.net-dt/css/jquery.dataTables.min.css"
  import $ from 'jquery';
  import moment from 'moment-timezone'

  export default 
  {
    name: 'App',
    components: 
    {
      Vue3ChartJs
    },
    data: () => (
    {
      GetAppPost: 
      {
        date: "",
        timezone: moment.tz.guess(true)
      },
      CurrentDate: 
      {
        date: ""
      },
      modelConfig: 
      {
        type: 'string',
        mask: 'YYYY-MM-DD', 
      },
      ListApps: 
      {
        status: "false",
        list: {}
      },
      mostrar: false,
    }),
    setup() 
    {
      const chartRef = ref(null)
      const doughnutChart = {
        id: 'doughnut',
        type: 'doughnut',
        responsive: 'true',
        data: {
          labels: [],
          datasets: [{
            backgroundColor: [],
            data: []
          }]
        },
        options: {
          plugins: {}
        }
      }

      const updateChart = (values, color, max) => {
        doughnutChart.options.plugins.title = {
          text: 'Updated Chart',
          display: true
        }
        doughnutChart.data.labels = values
        doughnutChart.data.datasets = [{
          backgroundColor: color,
          data: max
        }]
        chartRef.value.update(250)
      }

      const beforeRenderLogic = (event) => {
        //...
        //if(a === b) {
        //  event.preventDefault()
        //}   
      }

      return {
        doughnutChart,
        beforeRenderLogic,
        updateChart,
        chartRef
      }
    },
    methods: 
    {
      ActualDate()
      {
        let date = "";
        let year = new Date().getFullYear();
        let month = new Date().getMonth() + 1;
        let  month2 =  month>=10?month:'0'+ month;
        let day = new Date().getDate();
        date = year+"-"+month2+"-"+day;
        return date;
      },
      GetApps() 
      {
        Api.post("/activity/apps", this.GetAppPost).then((result) => 
        {
          this.ListApps.status = result.data.status
          
          if (this.ListApps.status == "true") 
          {
            let valuesChart = [];
            let colorChart = [];
            let maxChart = [];
            const generarColor = () => "#000000".replace(/0/g, () => (~~(Math.random() * 16)).toString(16))
            
            for (var i = 0; i < result.data.app.length; i++) 
            {
              valuesChart.push(result.data.app[i].name_process);
              colorChart.push(generarColor());
              maxChart.push(result.data.app[i].total);
            }
            
            this.updateChart(valuesChart, colorChart, maxChart);
            this.ListApps.list = result.data.app;
            this.$nextTick(() => 
            {
              this.InitDatatable()
            })
          }
        })
      },
      GetCurrentDate() 
      {
        var currentDateWithFormat = this.ActualDate();//new Date().toJSON().slice(0, 10).replace(/-/g, '-');
        this.GetAppPost.date = currentDateWithFormat
      },
      InitDatatable() 
      {
        var table = $('#app-table').DataTable(
        {
          dom: 'lftip',
          //dom: 'Blftip',
          responsive: true,
          destroy: true,
          lengthChange: false,
          aaSorting: [ [0,'asc'] ]
        });
      }
    },
    mounted() 
    {
      this.GetCurrentDate()
      this.GetApps()
    }
  }
</script>

<style scoped></style>
